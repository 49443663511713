import {codeData,qrcodeData,wxBind,accountLogin,codeLogin,register,userinfoData,ucenterMenu} from "../../../api/main/login.js";
let modules = {
	namespaced: true,
	state: {
		userinfo:localStorage['userinfo']?JSON.parse(localStorage['userinfo']):"",
		auth:localStorage["auth"]?localStorage["auth"]:"",
		avatar:localStorage["avatar"]?localStorage["avatar"]:"",
		ucmenu:localStorage['ucmenu']&&localStorage['ucmenu']!='null'?JSON.parse(localStorage['ucmenu']):[{url:'./','menu_id':0,title:'账号未授权'}],
	},
	mutations: {
		["SET_USER"](state,payload){
			state.userinfo = payload.userinfo
			state.auth = payload.auth
			state.avatar = payload.userinfo.avatar
			localStorage["userinfo"]=JSON.stringify(payload.userinfo)
			localStorage["auth"]=payload.auth
			localStorage["avatar"]=payload.userinfo.avatar
		},
		["OUT_LOGIN"](state){
			state.userinfo = ""
			state.auth = ""
			state.avatar = ""
			state.ucmenu = []
			localStorage.removeItem("userinfo")
			localStorage.removeItem("auth")
			localStorage.removeItem("avatar")
			localStorage.removeItem("ucmenu")
		},
		["SET_USERAVATAR"](state,payload){
			localStorage["avatar"]=payload.avatar
		},
		["SET_UCMENU"](state,payload){
			state.ucmenu = payload.ucmenu
			localStorage["ucmenu"]=JSON.stringify(payload.ucmenu)
		}
	},
	actions: {
		getCodeData(conText, payload){//获取手机短信验证码
			codeData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getQrcodeData(conText, payload){//获取二维码
			qrcodeData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		checkWxBind(conText, payload){//检测微信绑定
			wxBind(payload.data).then(res => {
				if(res.code==200){
					conText.commit("SET_USER",{userinfo:res.data.userinfo,auth:res.data.auth})
				}
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		DoAccountLogin(conText, payload){//账号密码登录
			// console.log(conText)
			accountLogin(payload.data).then(res => {
				// console.log('vuex success account login',res)
				if(res.code==200){
					conText.commit("SET_USER",{userinfo:res.data.userinfo,auth:res.data.auth})
				}
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		DoCodeLogin(conText, payload){//短信验证码登录
			codeLogin(payload.data).then(res => {
				// console.log('vuex success code login',res)
				if(res.code==200){
					conText.commit("SET_USER",{userinfo:res.data.userinfo,auth:res.data.auth})
				}
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		DoRegister(conText, payload){//账户注册
			register(payload.data).then(res => {
				// console.log('vuex success register',res)
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getUserinfoData(conText, payload){//获取个人信息
			userinfoData(payload.data).then(res => {
				if(res.code==200){
					// console.log('cccccccc',res)
					conText.commit("SET_USERAVATAR",{avatar:res.data.avatar})
				}
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getUcenterMenu(conText, payload){//获取菜单权限
			ucenterMenu(payload.data).then(res => {
				// console.log('vuex ucenterMenu',res)
				if(res.status==200){
					conText.commit("SET_UCMENU",{ucmenu:res.data})
				}
				if (payload.success) {
					payload.success(res)
				}
			})
		},
	}

}
export default modules;
