import {regionData,checkout,editAddress,create,payOrder,checkPayState} from "../../../api/main/order.js";
let modules = {
	namespaced: true,
	state: {


	},
	mutations: {
		
	},
	actions: {
		getRegionData(conText, payload){//获取省市区列表
			regionData(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCheckout(conText, payload){//结算页面
			checkout(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opEditAddress(conText, payload){//编辑收货地址
			editAddress(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opCreate(conText, payload){//提交订单
			create(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		opPayOrder(conText, payload){//支付获取付款二维码
			payOrder(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCheckPayState(conText, payload){//检查扫码支付状态
			checkPayState(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		
		
	}

}
export default modules;
