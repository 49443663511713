import config from "../../assets/js/conf/config";
import request from "../../assets/js/utils/request";


/**
 * 购物
 */
export function regionData(data){//获取省市区列表
	return request.get(config.baseApi+"/business/Order/regionData",data)
}
export function checkout(data){//结算页面
	return request.get(config.baseApi+"/business/Order/checkout",data)
}
export function editAddress(data){//编辑收货地址
	return request.post(config.baseApi+"/business/Order/editAddress",data)
}
export function create(data){//提交订单
	return request.post(config.baseApi+"/business/Order/create",data)
}
export function payOrder(data){//支付获取付款二维码
	return request.post(config.baseApi+"/business/Order/payOrder",data)
}
export function checkPayState(data){//检查扫码支付状态
	return request.post(config.baseApi+"/business/Order/checkPayState",data)
}





