import config from "../../assets/js/conf/config";
import request from "../../assets/js/utils/request";

//获取短信验证码
export function codeData(data){
	return request.post(config.baseApi+"/business/businesssend",data)
}

//获取二维码
export function qrcodeData(data){
	return request.post(config.baseApi+"/wechat/wxcode",data)
}

//检测微信绑定
export function wxBind(data){
	return request.post(config.baseApi+"/wechat/iscodelogin",data)
}

//账号密码登录
export function accountLogin(data){
	return request.post(config.baseApi+"/business/businesslogin",data)
}

//账号密码登录
export function codeLogin(data){
	return request.post(config.baseApi+"/business/businesscodelogin",data)
}

//账户注册
export function register(data){
	return request.post(config.baseApi+"/business/businessregister",data)
}

//账户注册
export function userinfoData(data){
	return request.post(config.baseApi+"/business/basinessuserinfo",data)
}

//获取菜单权限
export function ucenterMenu(data){
	return request.post(config.baseApi+"/business/System.Businessrole/getMenu",data)
}