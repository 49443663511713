import {addAllProduct,cartList,selectOptions,deleteOptions,moreSkus,submitPH} from "../../../api/main/cart.js";
let modules = {
	namespaced: true,
	state: {


	},
	mutations: {
		
	},
	actions: {
		doAddAllProduct(conText, payload){
			addAllProduct(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getCartList(conText, payload){//进货车列表
			cartList(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doSelectOptions(conText, payload){//进货车选中/取消商品
			selectOptions(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doDeleteOptions(conText, payload){//进删除进货车商品
			deleteOptions(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		getMoreSkus(conText, payload){//选择更多产品分类
			moreSkus(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		doSubmitPH(conText, payload){//提交铺货
			submitPH(payload.data).then(res => {
				if (payload.success) {
					payload.success(res)
				}
			})
		},
		
	}

}
export default modules;
