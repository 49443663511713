import Vue from 'vue'
import Vuex from 'vuex';
import index from './modules/main/index.js';
import ucenter from './modules/main/ucenter.js';
import login from './modules/main/login.js';
import cart from './modules/main/cart.js';
import order from './modules/main/order.js';


Vue.use(Vuex);

let store = new Vuex.Store({
	modules: {
		index,
		ucenter,
		login,
		cart,
		order,
	}
});

export default store;
